/*
* 업무구분: 신탁>영업>변경/해지/이체>이체>가상계좌등록
* 화 면 명: MSPTS209M
* 화면설명: 가상계좌등록[TFBM31000.xfdl:3100]
* 작 성 일: 2023.03.24
* 작 성 자: 박은희 
*/

/******************************************************************************************
 * Vue 메인 templates 설정 영역
 ******************************************************************************************/
<template>
  <ur-page-container class="fts" :show-title="false" :title=pHeaderObj.title :topButton="true">
    <!-- header start -->
    <ts-header
      ref="tsHeader"
      :propObj="pHeaderObj"
    ></ts-header>
    <!-- header end -->
     <!-- .fts-main -->
      <!--mask="#######-###" -->               
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left">
            <div class="wrap-input row">
              <label class="emphasis"> 통장번호 </label>
              <!-- (공통) 계좌 및 금액 관련 숫자입력 키패드 교체 적용, applyed by anarchi (2023.0318) // -->
              <!-- <mo-text-field 
                class="w130"
                v-model="div_tacno.tacno"
                maxlength="11"
                @keyup="fn_SearchValid ($event)"  
                @paste="fn_SearchValid ($event, 'P')"
              /> -->
              <mo-decimal-field 
                v-model="div_tacno.tacnoDisp"
                numeric
                mask="#######-###"
                class="w130"
                maxlength="11"
                @keyup="fn_SearchValid ($event)"  
                @paste="fn_SearchValid ($event, 'P')"
              />
              <mo-button class="btn-pop-download" @click="fn_OpenMSPTS206P"> </mo-button>
              <mo-text-field class="w130" v-model="div_tacno.cnm" disabled/>
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button v-if="isInitBtnDisabled" @click="fn_enableComponent" > 초기화 </mo-button>
              <mo-button v-if="isSchBtnDisabled" @click="fn_searchList" primary> 조회 </mo-button>
            </div>
          </div>
        </div>
        <div class="wrap-button result">
          <mo-button v-if="isRstBtnDisabled" @click="fn_insert"> 가상계좌 생성 </mo-button>
        </div>
      </ur-box-container>
      <ur-box-container direction="column" alignV="start">                 
        <div class="wrap-table-title mt-0">
          <h2 class="table-title"> 가상계좌 </h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2080">
            <!-- <colgroup>
              <col width="20%">
              <col width="30%">
              <col width="20%">
              <col width="30%">
            </colgroup> -->
            <tbody>
              <tr>
                <th>
                  <span> 가상계좌번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w260" disabled v-model="dispVtAcno"/>
                    <p>(가상계좌제휴은행 : 우리은행)</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="desc02 mt-3"><em class="colorR">※ 구고객번호 보유고객은 가상계좌번호가 자동생성 되지 않으므로, 가상계좌 생성 버튼을 눌러주세요.</em></p>
        </div>
		  </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button v-if="isMovBtnDisabled" primary size="large" @click="fn_goPage"> 신탁입금 이동 </mo-button>
        </div>
      </ur-box-container>
    </div>
    <!-- //.fts-main -->
    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>
    <msp-ts-206p
      ref="popup206"
      :popup206Obj="pPopup206Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup206-callback="fn_Popup206Back"
    ></msp-ts-206p>    
  </ur-page-container>
</template>

<script>
  const defaultSearchInfo = {
    tacno: "",     // 종합계조번호
    bnkbSeq: "",   // 일련번호
    accSeq: "",    // AccSeq
    tacnoDisp: "", // 종합계좌번호(화면용) 추가,
    brigAccYn: "",
    rcno: "",
    rcnoKindTc: "",
    csId: "",
    cnm: "",
  };
  const defaultSearch = {
    tacno: "",     // 종합계조번호
    bnkbSeq: "",   // 일련번호
  };
  const defaultDetail = {
    tacno			: "",
    bnkbSeq         : "",
    csId            : "",
    linkBkcd        : "",
    vtAcno          : "",
    msUseYn         : "",
    bnkbIssncYn     : "",
    vtAcnoYn        : "",
    sha2Pswd        : "",
    procGb          : "",
    crtDt           : "",
    regDate         : "",
    tmtlDate        : "",
    accStatC        : "", // ☆☆☆ 계좌조회시 오류원인 (PO 서비스 임의변경 추정됨) 확인요, checked by anarchi
    regBrcd         : "",
    vaRnoYn         : "",
    maacRegDate     : "",
    maacRegYn       : "",
    maacRegNo       : "",
    regCnt          : "",
    refNo           : "",
    tassIssncAccYn  : "",
  };
  const defaultPbpr = {
    pbprTp		: "",
    pbprCntl    : "",
    pbprPageNo  : "",
    pbprLineNo  : "",
    pbprOutLen  : "",
    pbprData    : "",
    pbprPrtCnt  : "",
  };
  const defaultLinkAcctDetail = {
    msUseYn				      : "",
    vtAcnoYn            : "",
    tacno               : "",
    bnkbSeq             : "",
    refNo               : "",
    vtAcno              : "",
    passBookBnkbIssncYn : "",
    pswdCheckYn         : "",
  };
  const defaultDivTacnoSearch = {
      tacno: "",
      bnkbSeq: "",
      accSeq: "", // ★☆☆ 계좌조회시 오류원인 (PO 서비스 임의변경 추정됨) 확인요, 정보항목 추가함(계좌일련번호). added by anarchi
      accStatC: "", // ☆☆☆ 계좌조회시 오류원인 (PO 서비스 임의변경 추정됨) 확인요, checked by anarchi
      brigAccYn: "",
  };
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import _cloneDeep from "lodash/cloneDeep"
  // import moment from "moment"

  import TSAlertPopup from "~/src/ui/ts/comm/TSAlertPopup"
  import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil"   
  import TSHeader from "~/src/ui/ts/comm/TSHeader" // header 영역 (공통)
  import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil"   

  import MSPTS206P from "@/ui/ts/MSPTS206P" //계좌번호 조회

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPTS209M",
    screenId: "MSPTS209M",
    components: {
      "ts-header": TSHeader,      
      "ts-alert-popup": TSAlertPopup, 
      "msp-ts-206p": MSPTS206P,
    },
    /******************************************************************************************
     * Watch 설정 영역
     ******************************************************************************************/
    watch: {
      //
    },
    /******************************************************************************************
     * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Created 설정 영역
     ******************************************************************************************/
    created () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 시작 ]");
      let lv_vm = this
      window.fdpbridge.exec ('RecordStatusPlugin', {}, (result) => {
        try {
          if (result.data === 'Playing') {
            lv_vm.fn_trace ('info', '녹취 진행중...')
          }
          else if (result.data === 'Stop') {
            lv_vm.fn_trace ('error', '녹취 중단 !!!', result.data)
            lv_vm.fn_AlertMsg('error', '녹취가 중단되었습니다.\n중단하시겠습니까?', '아니오|예')
          } 
        }
        catch (error) {
          lv_vm.fn_trace ('error', '녹취 진행 중 오류 발생')
        }
      }, () => {
        lv_vm.fn_trace ('error', '녹취 진행 중 오류 발생')
      })
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 종료 ]");
    },
    /******************************************************************************************
     * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Mounted 설정 영역
     ******************************************************************************************/
    mounted () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 시작 ]");
      this.$bizUtil.insSrnLog ("MSPTS209M");
      // if ( this.$route.params.tacno != null  &&  this.$route.params.tacno) {
      //     this.div_tacno.tacno = TSCommUtil.gfn_mask (this.$route.params.tacno)
      //     this.fn_transaction ();
      // }
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [라우터 내장객체-(수신)파라미터]", " this.$route.params → ", this.$route.params);
      if (this.isLoggable) console.log ("+++++++++");
      // let _cloneDeep = require ('lodash/cloneDeep');
      if (this.isLoggable) console.log ("_cloneDeep", _cloneDeep);
      // this.pParams = this.$_.cloneDeep (this.$route.params);
      this.pParams = _cloneDeep (this.$route.params);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [수신 파라미터-저장변수]", " this.pParams → ", this.pParams);
      if (this.isLoggable) console.log ("+++++++++");
      this.$nextTick (() => {
        if (!TSCommUtil.gfn_isNull (this.pParams.tacno)) {
          let accSeq = this.pParams.accSeq;
          let tacno = this.pParams.tacno;
          let bnkbSeq = this.pParams.bnkbSeq;
          // let accSeq = "003";
          // let bnkbSeq = "999";
          // let tacno = "1102075";
          this.div_tacno.tacno = tacno;
          this.div_tacno.accSeq = accSeq;
          this.div_tacno.bnkbSeq = bnkbSeq;
          this.div_tacno.tacnoDisp = tacno + "-" + bnkbSeq;
          // 자동조회
          // this.fn_transaction ();
          this.fn_SearchValid ();
        }
      });
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 종료 ]");
    },
    /******************************************************************************************
     * Data 설정 영역
     ******************************************************************************************/
    data () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 시작 ]");
      return {
        // ------
        // 개발/검증용 로깅출력 여부, added by anarchi (2023.08.7)
        // (※ 개발시: true, 운영이관시: false 설정할 것)
        // ------
        isLoggable: false,
        // 로그인정보
        lv_basInfo: this.getStore ("tsStore").getters.getBasInfo.data,
        // 공통 객체
        pHeaderObj: {
          // step: "7",
          title: "가상계좌등록",
        },
        eaiCommObj: TSCommUtil.gfn_eaiCommObj (),
        // 팝업 객체
        pAlertPopupObj: {},
        ds_search: Object.assign ({}, defaultSearch),
        ds_detail: Object.assign ({}, defaultDetail),
        ds_pbpr: Object.assign ({}, defaultPbpr),
        ds_linkAcctDetail: Object.assign ({}, defaultLinkAcctDetail),
        div_tacno: Object.assign ({}, defaultSearchInfo),
        ds_divTacnoSearch: Object.assign ({}, defaultDivTacnoSearch), 
        //최초 버튼 설정
        isInitBtnDisabled: true, // 조회버튼
        isSchBtnDisabled: true, // 초기화버튼
        isRstBtnDisabled: false, // 가상계좌등록버튼
        isMovBtnDisabled: false, // 신탁이동버튼
        bnkbSeq: "", // 통장일련번호
        csId: "", // 고객ID
        tacno: "", // 통합계좌번호
        vtAcno: "", // 가상계좌번호(조회값)
        dispVtAcno: "", // 가상계좌번호(화면출력값)
        cnm: "", // 고객명
        tassIssncAccYn: "", // 신규고객여부
        pswdCheckYn: "N", // 비밀번호 체크
        msUseYn: "Y", // MS사용여부 (30000 화면에선 무조건)
        vtAcnoYn: "Y", // 가상계좌여부
        pPopup206Obj: {},
        pPopup206Type: "",
        popup206: {}, // MSPTS206P
        pParams: {}, // (업무프로세스) 전단계에서 전달받은 파라미터
      };
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 종료 ]");
    },
    /******************************************************************************************
     * Computed 설정 영역
     ******************************************************************************************/
    computed: {
      // modal
      modal1 () {
        if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal1 ]___[ 시작 ]");
        return this.$refs.modal1;
        if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal1 ]___[ 종료 ]");
      },
      modal2 () {
        if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal2 ]___[ 시작 ]");
        return this.$refs.modal2;
        if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal2 ]___[ 종료 ]");
      },
      isProcessCheck () {
        if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 시작 ]");
        return this.getStore ("tsStore").getters.getState.isProcess;
        if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 종료 ]");
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * 참조1 - 수정, 확인, 버튼 클릭 후 다음 프로세스 진행 시
       ******************************************************************************/
      fn_checkProcess () {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_checkProcess ]___[ 시작 ]");
        let lv_Vm = this;
        let tmpContentTitle = "";
        let tmpContent = [];
        let isProcess = lv_Vm.getStore ("tsStore").getters.getState.isProcess; // 프로세스 진행 여부
        let basInfo = lv_Vm.getStore ("tsStore").getters.getBasInfo.data;
        let isLast = lv_Vm.$bizUtil.tsUtils.getIsLastProc (lv_Vm); // 마지막화면 여부 (각 프로세스 별 마지막 화면 여부)
        let t_type = 0;
        if (isProcess) {
          if (!isLast) { // 프로세스 진행 중 화면
            tmpContent.push ("입금완료");
            tmpContent.push ("신탁 입금이 완료되었습니다.");
            if (basInfo.procTyp === "custInfoReg") {  
              tmpContent.push ("가상계좌등록 화면으로 이동합니다.");
            } // 프로세스 진행 중 화면
            t_type = 7; // 프로세스 진행 중 마지막 화면이 아닐 경우
          }
          else { // 마지막 화면
            t_type = 9; // 프로세스 진행 중 마지막 화면일 경우
          }
        }
        else { // 프로세스 진행 중이 아닐 경우
           t_type = 9;
        }
        let t_popupObj = {
          confirm: true,
          confirmFunc: lv_Vm.fn_ReportPrint,
          confirmData: {
            tacno: lv_Vm.tacno, // 계좌번호 , 없을 경우 빈값으로 표시
          },
          content: tmpContent,
          contentTitle: tmpContentTitle,
        };
        lv_Vm.fn_AlertPopup (t_type, t_popupObj);
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_checkProcess ]___[ 종료 ]");
      },
      // ======================================================
      // (화면흐름 제어) openModal:: 모달 화면 및 팝업 열기
      // ======================================================
      openModal (type) {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::openModal ]___[ 시작 ]");
        switch (type) {
          case 1:
            this.modal1.open ();
            break;
          case 2: 
            this.modal2.open ();
            break;
        }
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::openModal ]___[ 종료 ]");
      },
      // ======================================================
      // (화면흐름 제어) closeModal:: 모달 화면 및 팝업 닫기
      // ======================================================
      closeModal (type) {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::closeModal ]___[ 시작 ]");
        switch (type) {
          case 1:
            this.modal1.open ();
            break;
          case 2:
            this.modal2.open ();
            break; 
        }
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::closeModal ]___[ 종료 ]");
      },
      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통-연계용)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup ]___[ 시작 ]");
        switch (type) {
          case 0:
            if ( !TSCommUtil.gfn_isNull (pPopupObj) ) {
              this.pAlertPopupObj.content = pPopupObj.content;
              this.pAlertPopupObj.contentTitle = '';
              this.pAlertPopupObj.confirm = false;
              this.pAlertPopupObj.cancel = true;
              this.pAlertPopupObj.type = 0;
              if ( !TSCommUtil.gfn_isNull (pPopupObj.confirm) ) {
                this.pAlertPopupObj.confirm = pPopupObj.confirm;
              }
              else {
                this.pAlertPopupObj.confirm = '';
              }
              if ( !TSCommUtil.gfn_isNull (pPopupObj.confirmFunc) ) {
                this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
              }
              else {
                this.pAlertPopupObj.confirmFunc = '';
              }
              if ( !TSCommUtil.gfn_isNull (pPopupObj.closeFunc) ) {
                this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc;
              }
              else {
                this.pAlertPopupObj.closeFunc = '';
              }
              if ( !TSCommUtil.gfn_isNull (pPopupObj.single) ) {
                this.pAlertPopupObj.single = pPopupObj.single;
              }
              else {
                this.pAlertPopupObj.single = '';
              }
            }
            break
          case 7:
            //
          case 9:
            if ( !TSCommUtil.gfn_isNull (pPopupObj) ) {
              this.pAlertPopupObj.content = pPopupObj.content;
              this.pAlertPopupObj.contentTitle = '';
              this.pAlertPopupObj.confirm = false;
              this.pAlertPopupObj.cancel = true;
              this.pAlertPopupObj.type = 0;
              if ( !pPopupObj.cancel ) {
                this.pAlertPopupObj.cancel = pPopupObj.cancel;
              }
              if ( pPopupObj.confirm ) {
                this.pAlertPopupObj.confirm = pPopupObj.confirm;
                this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
                this.pAlertPopupObj.confirmData = pPopupObj.confirmData;
              }
              if ( pPopupObj.closeFunc ) {
                this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc;
                this.pAlertPopupObj.closeData = pPopupObj.closeData;
              }
              if ( !TSCommUtil.gfn_isNull (type) && type != 0 ) {
                this.pAlertPopupObj.type = type;
              }
              if ( pPopupObj.contentTitle ) {
                this.pAlertPopupObj.contentTitle = pPopupObj.contentTitle;
              }
            }
            break
        }
        this.$refs.alertPopup.fn_Open ();
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup ]___[ 종료 ]");
      },
      /******************************************************************************
       * Function명 : fn_AlertPopup2
       * 설명       : alert popup 호출(공통-기존백업용)
       ******************************************************************************/      
      fn_AlertPopup2 (type, pPopupObj) {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup2 ]___[ 시작 ]");
        switch (type) {
          case 0:
            if (!TSCommUtil.gfn_isNull (pPopupObj)) {
              this.pAlertPopupObj.content = pPopupObj.content;
              if (pPopupObj.confirm) {
                this.pAlertPopupObj.confirm = pPopupObj.confirm;
                this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
              }
            }
            break;
        }
        this.$refs.alertPopup.fn_Open ();
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup2 ]___[ 종료 ]");
      },
      /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type) : 0: 고객정보
       ******************************************************************************/
      fn_OpenPopup (type) {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenPopup ]___[ 시작 ]");
        switch (type) {
          case 0:
            this.$refs.popup308.fn_openModal ();
            break          
          case 1:
            break;
        }
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenPopup ]___[ 종료 ]");
      },
      /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type) : 0: 종합통장정보
       ******************************************************************************/
      fn_Popup_CallBack (type, pData) {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup_CallBack ]___[ 시작 ]");
        switch (type) {
          case 1:
            this.tacno = pData.tacno; // tacno
            this.bnkbSeq = pData.bnkbSeq; // 통장일련번호
            this.csId = pData.csId; // 고객ID
            this.linkBkcd = pData.linkBkcd; // 연계상품코드(020)
            break;
        }
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup_CallBack ]___[ 종료 ]");
      },
      /******************************************************************************
       * Function명 : fn_init
       * 설명       : 초기화 조회
       ******************************************************************************/
      fn_init () {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_init ]___[ 시작 ]");
        this.div_tacno = Object.assign ({}, defaultSearchInfo);
        this.ds_divTacnoSearch = Object.assign ({}, defaultDivTacnoSearch);
        this.vtAcno = "";
        this.dispVtAcno = "";
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_init ]___[ 종료 ]");
      },
      /******************************************************************************
       * Function명 : fn_enableComponent
       * 설명       : 초기화 
       ******************************************************************************/
      fn_enableComponent () {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_enableComponent ]___[ 시작 ]");
        this.fn_init ();
        // 버튼 제어 관련 추가해야함
        // this.Div00.div_btnList.btn_searchList.set_enable(true);
        // this.Div00.div_btnList.btn_confirm.set_enable(false);
        // this.Div00.div_btnList.btn_cancel.set_enable(false);
        // this.Div00.div_btnList.btn_init.set_enable(true);* 
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_enableComponent ]___[ 종료 ]");
      },
      /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 필수값 검증 후 조회
       ******************************************************************************/
      fn_SearchValid (event, flag = "") {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchValid ]___[ 시작 ]");
				let t_accn_no = "";
        if (flag == "P") {
          t_accn_no = (event.clipboardData || window.clipboardData).getData ("text").replace (/[^0-9]/g, '').substr (0, 10);
        }
        else {
          // t_accn_no = this.div_tacno.tacno.replace (/[^0-9]/g, '').substr (0, 10);
          t_accn_no = this.div_tacno.tacnoDisp.replace (/[^0-9]/g, '').substr (0, 10);
        } 
        if (TSCommUtil.gfn_length (t_accn_no) == 10) {
           this.fn_transaction ();
          //  this.div_tacno.tacno = TSCommUtil.gfn_mask (t_accn_no);
          //  this.div_tacno.tacnoDisp = TSCommUtil.gfn_mask (t_accn_no);
           this.div_tacno.tacno = TSCommUtil.gfn_mask (t_accn_no);
        }
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchValid ]___[ 종료 ]");
      },
      /******************************************************************************
       * Function명 : fn_transaction
       * 설명       : 계좌번호 조회  
       ******************************************************************************/
      fn_transaction () {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_transaction ]___[ 시작 ]");
        // debugger;
        // ---------
        // EAI(PO) 전송 파라미터 설정
        // ---------
        // this.div_tacno.tacno = this.div_tacno.tacno;
        // let t_accn_no = this.div_tacno.tacno.replace (/[^0-9]/g, '').substr (0, 10);
        let t_accn_no = this.div_tacno.tacnoDisp.replace (/[^0-9]/g, '').substr (0, 10);
        this.ds_divTacnoSearch.tacno = t_accn_no.substr (0, 7);
        this.ds_divTacnoSearch.bnkbSeq = t_accn_no.substr (7, 3);
        this.ds_divTacnoSearch.accSeq = this.div_tacno.accSeq; // 추가함
        this.ds_divTacnoSearch.accStatC = this.div_tacno.accSeq; // ☆☆☆ 계좌조회시 오류원인 (08.18까지 정상기능했던 로직으로 PO 서비스 임의변경으로 발생된 것으로 추정됨) 확인요, checked by anarchi
        this.ds_divTacnoSearch.brigAccYn = this.div_tacno.brigAccYn;
        // ---------
        // 기존 검색 결과 초기화 함수 필요함
        // 의뢰요청함 2023-04-21
        // ---------
        // ---------
        // □ 원격트랜잭션서비스(PO) 호출
        // 'C392_F10600245_S' |
        // 'UF10600245' // [NEXT 사랑On 신탁]계좌 정보 조회 TR 전송
        // ---------
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        // this.eaiCommObj.trnstId = 'txTSSTS49S9' // selectTPUP95090List
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600245_S'
        // Object.assign (this.eaiCommObj.params, this.ds_divTacnoSearch)
        this.eaiCommObj.params = {
          tacno: this.ds_divTacnoSearch.tacno,
          bnkbSeq: this.ds_divTacnoSearch.bnkbSeq,
          accSeq: this.ds_divTacnoSearch.accSeq, // 추가함
          // accStatC: this.ds_divTacnoSearch.accStatC, // ☆☆☆ 계좌조회시 오류원인 (PO 서비스 임의변경 추정됨) 확인요, checked by anarchi
          brigAccYn: this.ds_divTacnoSearch.brigAccYn,
        };
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [계좌 정보 조회 TR 전송]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
        if (this.isLoggable) console.log ("+++++++++");
        // TSServiceUtil.invoke(this.eaiCommObj, this.fn_transactionResult)
        TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_transactionResult);
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_transaction ]___[ 종료 ]");
      } ,
      /******************************************************************************
       * Function명 : fn_transactionResult
       * 설명       : 계좌번호 조회 후처리
       ******************************************************************************/
      fn_transactionResult (res) {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_transactionResult ]___[ 시작 ]");
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [계좌 정보 조회 TR 전송]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)리턴객체]", " res → ", res);
        if (this.isLoggable) console.log ("+++++++++");
        let t_data = res.data
        if (!TSCommUtil.gfn_isNull (t_data.errorMsg)) {
          this.fn_AlertPopup (0, {
            content: t_data.errorMsg,
          });
          return;
        }
        if (t_data.tpup95090.length > 0) {
          this.div_tacno.cnm = t_data.tpup95090[0].cnm;
          this.ds_search.tacno = t_data.tpup95090[0].tacno.substr (0, 7);
          this.ds_search.bnkbSeq = t_data.tpup95090[0].tacno.substr (7, 3);
          this.ds_detail.tacno = t_data.tpup95090[0].tacno.substr (0, 7);
          this.ds_detail.bnkbSeq = t_data.tpup95090[0].tacno.substr (7, 3);
          this.ds_detail.csId = t_data.tpup95090[0].csId;
          this.ds_detail.linkBkcd = "020";
          // ---------
          // 프로세스일 경우 신탁입금으로 이동 버튼 활성화
          // if (this.isProcessCheck) this.isMovBtnDisabled = true;
          // ---------
        }
        if (this.pParams.tacno != undefined || this.pParams.tacno != null) { //
          this.fn_searchList ();
        } 
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_transactionResult ]___[ 종료 ]");
      },  
      /******************************************************************************
       * Function명 : fn_searchList
       * 설명       : 가상계좌 상세 조회
       ******************************************************************************/
      fn_searchList () {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchList ]___[ 시작 ]");
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [(프로세스)가상계좌 조회 시, 종합계좌번호 및 계좌일련번호]", " this.div_tacno → ", this.div_tacno);
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("this.div_tacno.tacnoDisp::::: ", this.div_tacno.tacnoDisp);
        if (TSCommUtil.gfn_isNull (this.div_tacno.tacnoDisp)) {
          this.fn_AlertPopup (0, {
            content: "통장번호 입력오류입니다.",
          });
          return;
        } 
        // if (TSCommUtil.gfn_length (this.div_tacno.tacnoDisp) !== 10) {
        //   this.fn_AlertPopup (0, {
        //     content: "통장번호 입력오류입니다.",
        //   });
        //   return;
        // } 
        // ---------
        // EAI(PO) 전송 파라미터 설정
        // ---------
        // let t_accn_no = this.div_tacno.tacno.replace (/[^0-9]/g, '').substr (0, 10);
        // this.ds_search.tacno = t_accn_no.substr (0, 7);
        // this.ds_search.bnkbSeq = t_accn_no.substr (7, 3);
        let t_accn_no = this.div_tacno.tacnoDisp.replace (/[^0-9]/g, '').substr (0, 10);
        this.ds_search.tacno = t_accn_no.substr (0, 7);
        this.ds_search.bnkbSeq = t_accn_no.substr (7, 3);
        // ---------
        // □ 원격트랜잭션서비스(PO) 호출
        // 'C392_F10600129_S' |
        // 'UF10600129' // [NEXT 사랑On 신탁]가상계좌 상세조회-selectTFBM31000
        // ---------
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        // this.eaiCommObj.trnstId = 'txTSSTS31S2' // selectTFBM31000
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600129_S";
        this.eaiCommObj.params = {
          tacno: this.ds_search.tacno,
          bnkbSeq: this.ds_search.bnkbSeq,
        };
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [가상계좌 상세조회]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
        if (this.isLoggable) console.log ("+++++++++");
        // TSServiceUtil.invoke(this.eaiCommObj, this.fn_searchListCallBack)     
        TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_searchListCallBack);
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchList ]___[ 종료 ]");
      },
      /******************************************************************************
       * Function명 : fn_searchListCallBack
       * 설명       : 가상계좌 상세 조회 후처리
       ******************************************************************************/
      fn_searchListCallBack (pResultData) {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchListCallBack ]___[ 시작 ]");
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [가상계좌 상세조회]_[EAI(PO)_(", pResultData.commHeaderVO.eaiId, ")_(송신$콜백)리턴객체]", " pResultData → ", pResultData);
        if (this.isLoggable) console.log ("+++++++++");
        let t_data = pResultData.data;
        if ( t_data.errorMsg.trim ().length != 0 ) { // 에러시 처리 필요
          this.fn_AlertPopup (0, {
            content: t_data.errorMsg,
          });
        }
        else {
          // ---------
          //  * 성공시 신탁입금 이동 가능...
          //  * 가상계좌번호 화면에 연결..
          // this.vtAcno = t_data.......// 값
          // ---------
          // ---------
          // 프로세스일 경우 신탁입금으로 이동 버튼 활성화
          if (this.isProcessCheck) this.isMovBtnDisabled = true;
          // if (!this.isProcessCheck) this.isMovBtnDisabled = true;
          // ---------
          if (t_data.tassIssncAccYn == "Y") {
            this.vtAcno = t_data.vtAcno;
            this.dispVtAcno = this.stringBnkAcnoFormat (t_data.vtAcno);
            return;
          }
          else if (t_data.vtAcno.trim ().length != 0) { //
            this.fn_AlertPopup (0, {
              content: "이미 등록된 고객입니다.",
            });
            this.vtAcno = t_data.vtAcno;
            this.dispVtAcno = this.stringBnkAcnoFormat (t_data.vtAcno);
            return;
          }
          else if (t_data.vtAcno.trim ().length == 0) { //
            // if (!this.isProcessCheck) {
            //   this.fn_AlertPopup (0, {
            //     content: "신규고객은 처리불가합니다.",
            //   });
            // }
            return;
          }
          else {
              // 확인/취소 버튼 활성화 && 비번입력란 활성화 라고 기존 소스 
              // 인쇄버튼 비활성화 
              this.fn_insert ();
          }
        }
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchListCallBack ]___[ 종료 ]");
      },
      /******************************************************************************
       * Function명 : fn_insert
       * 설명       : 가상계좌 등록
       ******************************************************************************/
      fn_insert () {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_insert ]___[ 시작 ]");
        if (TSCommUtil.gfn_isNull (this.div_tacno.tacno)) {
          this.fn_AlertPopup (0, {
            content: "통장번호를 입력하세요.",
          });
          return;
        }
        // ---------
        // EAI(PO) 전송 파라미터 설정
        // ---------
        let t_accn_no = this.div_tacno.tacno.replace (/[^0-9]/g, '').substr (0, 10);
        this.ds_linkAcctDetail.msUseYn = "Y";
        this.ds_linkAcctDetail.vtAcnoYn = "Y";
        this.ds_linkAcctDetail.tacno = t_accn_no.substr (0, 7);
        this.ds_linkAcctDetail.bnkbSeq = t_accn_no.substr (7, 3);
        this.ds_linkAcctDetail.pswdCheckYn = "N";
        // ---------
        // □ 원격트랜잭션서비스(PO) 호출
        // 'C392_F10600123_S' |
        // 'UF10600123' // [NEXT 사랑On 신탁]가상계좌 등록-insertTFBM30000A
        // ---------
        this.eaiCommObj.lv_vm = this;
        //this.eaiCommObj.trnstId = "txTSSTS30I1" // insertTFBM30000
        this.eaiCommObj.auth = "I";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600123_S";
        this.eaiCommObj.params = {
            msUseYn:  this.ds_linkAcctDetail.msUseYn,
            vtAcnoYn:this.ds_linkAcctDetail.vtAcnoYn,
            tacno:this.ds_linkAcctDetail.tacno,
            bnkbSeq:this.ds_linkAcctDetail.bnkbSeq,
            pswdCheckYn:this.ds_linkAcctDetail.pswdCheckYn,
        };
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [가상계좌 등록]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
        if (this.isLoggable) console.log ("+++++++++");
        //TSServiceUtil.invoke(this.eaiCommObj, this.fn_insertCallBack)   
        TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_insertCallBack);
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_insert ]___[ 종료 ]");
      },
      /******************************************************************************
       * Function명 : fn_insertCallBack
       * 설명       : 가상 계좌 등록 후처리
       ******************************************************************************/
      fn_insertCallBack (pResultData, pErrorCode) {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_insertCallBack ]___[ 시작 ]");
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶▷ [가상계좌 등록]_[EAI(PO)_(", pResultData.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " pResultData → ", pResultData, ", pErrorCode → ", pErrorCode);
        if (this.isLoggable) console.log ("+++++++++");
        let t_data = pResultData.data;
        if (t_data.errorCode.trim ().length != 0) {
            this.fn_AlertPopup (0, {
              content: t_data.errorCode,
            });
        }
        else {
            // 소스에는 만기보험금 계좌 등록 기능이 있다.
            this.ds_linkAcctDetail.vtAcno = t_data.tfbm31000.vtAcno;
            // this.ds_linkAcctDetail.vtAcno = t_data.tfbm31000.vtAcno
            this.fn_linkAcctInsert ();
        }
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_insertCallBack ]___[ 종료 ]");
      },
      // ======================================================
      // (공통:데이터 검증) fn_SubmitVaild:: 만기보험금 계좌등록 시, 필수입력값 검증
      // ======================================================
      fn_SubmitVaild () {
        if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::fn_SubmitVaild ]___[ 시작 ]");
        // ---------
        // (인수인계시, 미구현 상태)
        // ASIS 로직은 어디에???
        // ... commented by anarchi (2023,08.10)
        // ---------
        return true;
        if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::fn_SubmitVaild ]___[ 종료 ]");
      },
      /******************************************************************************
       * Function명 : fn_linkAcctInsert
       * 설명       : 만기보험금 계좌 등록
       ******************************************************************************/
      fn_linkAcctInsert () {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_linkAcctInsert ]___[ 시작 ]");
        // 입력/선택 필수값 검증
        if (!this.fn_SubmitVaild ()) {
          return;
        }
        // ---------
        // EAI(PO) 전송 파라미터 설정
        // ---------
        let t_accn_no = this.div_tacno.tacno.replace (/[^0-9]/g, '').substr (0, 10);
        this.ds_linkAcctDetail.msUseYn = 'Y';
        this.ds_linkAcctDetail.vtAcnoYn = 'Y';
        this.ds_linkAcctDetail.tacno = t_accn_no.substr (0, 7);
        this.ds_linkAcctDetail.bnkbSeq = t_accn_no.substr (7, 3);
        this.ds_linkAcctDetail.vtAcno = this.vtAcno;
        // ---------
        // □ 원격트랜잭션서비스(PO) 호출
        // 'C392_F10600128_S' |
        // 'UF10600128' // [NEXT 사랑On 신탁]만기보험금 지급계좌목록에 등록-insertTFBM31000A
        // ---------
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = 'I';
        // this.eaiCommObj.trnstId = 'txTSSTS31I2'; // insertTFBM31000A
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600128_S';
        this.eaiCommObj.params = {
            msUseYn:'Y',
            vtAcnoYn:'Y',
            tacno:this.ds_linkAcctDetail.tacno,
            bnkbSeq:this.ds_linkAcctDetail.bnkbSeq,
            vtAcno:this.ds_linkAcctDetail.vtAcno,
        };
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [만기보험금 지급계좌목록에 등록]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
        if (this.isLoggable) console.log ("+++++++++");
        //TSServiceUtil.invoke(this.eaiCommObj, this.fn_linkAcctInsertCallBack)      
        TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_linkAcctInsertCallBack);
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_linkAcctInsert ]___[ 종료 ]");
      },
      /******************************************************************************
       * Function명 : fn_linkAcctInsertCallBack
       * 설명       : 만기보험금 계좌 등록 후처리
       ******************************************************************************/
      fn_linkAcctInsertCallBack (pResultData, pErrorCode) {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_linkAcctInsertCallBack ]___[ 시작 ]");
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶▷ [만기보험금 지급계좌목록에 등록]_[EAI(PO)_(", pResultData.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " pResultData → ", pResultData, ", pErrorCode → ", pErrorCode);
        if (this.isLoggable) console.log ("+++++++++");
        let t_data = pResultData.data;
          if (t_data.errorMsg.trim ().length != 0) {
              // this.getStore ('confirm').dispatch ('SETCOLOR', 'purple')
              // this.getStore ('confirm').dispatch ('ADD', t_data.errorMsg)
          }
          else {
              // 처리 결과 로직 추가 필요
              // 성공시 가상계좌번호 정보 화면에 보여주며 신탁입금 이동관련 진행
              // 소스에는 만기보험금 계좌 등록 기능이 있다.
              // TSCommUtil.gfn_getMessage( "alert", "만기보험금 계좌 등록 중 입니다.");
          }
          if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_linkAcctInsertCallBack ]___[ 종료 ]");
        },
      /******************************************************************************
       * Function명 : fn_goPage
       * 설명       : 신탁입금 화면 이동 this.pParams
       ******************************************************************************/        
      fn_goPage () {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_goPage ]___[ 시작 ]");
        let _pParams = {
          name: "MSPTS207M",
          params: this.pParams,
          // params: {
          //   accSeq: this.ds_divTacnoSearch.accStatC,
          //   bnkbSeq: this.ds_divTacnoSearch.bnkbSeq,
          //   tacno: this.ds_divTacnoSearch.tacno,
          // },
        }; // this.ds_divTacnoSearch
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [신탁입금 화면이동]_[(송신)-파라미터_(NEW)]", " _pParams → ", _pParams);
        if (this.isLoggable) console.log ("+++++++++");
        this.$router.push (_pParams);
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_goPage ]___[ 종료 ]");
      },
      /*********************************************************
       * Function명: fn_OpenMSPTS206P
       * 설명: 계좌번호 조회 팝업호출
       *********************************************************/
      fn_OpenMSPTS206P (type) {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS206P ]___[ 시작 ]");
        let lv_vm = this;
        this.pPopup206Type = type;
        let properties = {
          pPage: "MSPTS210M",             
          // pGdC: lv_vm.gdC, // 신탁상품
          // pTrstTypeC: lv_vm.trstTypC, // 신탁유형
          // pGdTypDtlC: lv_vm.gdTypDtlC, // 상품유형
          pUrl: "2000", // 
          pProcTc: "2000", //
        };
        this.popup206 = this.$refs.popup206.fn_Open (properties);
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS206P ]___[ 종료 ]");
      },
      /*********************************************************
       * Function명: fn_Popup206Back
       * 설명: 계좌번호 조회 팝업호출 콜백
       *********************************************************/
      fn_Popup206Back (rtnData) {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup206Back ]___[ 시작 ]");
        if (rtnData != null) {
          this.vtAcno = "";
          this.div_tacno.tacno = rtnData.tacno+rtnData.bnkbSeq;
          this.div_tacno.cnm = rtnData.cnm;
        }
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup206Back ]___[ 종료 ]");
      },
      /************************************************************************************************
       * Function명 : stringBnkAcnoFormat
       * 설명       : 352*****56710
       * @param {String} value : 통장번호 형식 변경 XXXXXXXX-XXX-XXX
       *                                           26431303 218 638    
       ************************************************************************************************/
      stringBnkAcnoFormat (value) {
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::stringBnkAcnoFormat ]___[ 시작 ]");
        if (!value) return "";
        // ---------------------------
        // 가상계좌 조회번호 문자열포맷팅 시, 파싱오류 보정함, corrected by anachi (2023.09.18)
        // value = value.substr (0, 8) + "-" + value.substr (9, 3) + "-" + value.substr (11, value.length - 11);
        // ---------------------------
        value = value.substr (0, 8) + "-" + value.substr (8, 3) + "-" + value.substr (11, value.length - 11);
        return value;
        if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::stringBnkAcnoFormat ]___[ 종료 ]");
      },  
    },
  }
</script>

<style lang="scss"></style>
<style scoped></style>
